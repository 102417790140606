import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import ContactForm from "@/components/contact-form";
import PageBanner from "@/components/page-banner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
// import CalendifyComponent from "@/components/calendify";
import { Container, Col, Row } from "react-bootstrap";

const ContactPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Contacta con nosotros - Nuestro equipo de Shopify Experts contactará contigo - Witocorp">
          <HeaderOne />
          <PageBanner title="Contacto" name="Contact" />
          <ContactForm />
          {/* <CalendifyComponent /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ContactPage;
