import React, { useState, useEffect } from "react";
import { ContactFormTitle } from "@/data";
import Alert from "react-bootstrap/Alert";
const fetch = require("node-fetch");

const ContactForm = () => {
  const { subTitle, title, description } = ContactFormTitle;

  const [msg, setMsg] = useState(false);
  const [msgColor, setMsgColor] = useState("");
  const [msgText, setMsgText] = useState("");
  const [input, setInput] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: Number(),
    descriptionClient: "",
  });

  const handleChange = e => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {}, [msg]);

  const sendPost = e => {
    e.preventDefault();
    const body = {
      email: input.email,
      name: input.name,
      lastName: input.lastName,
      phone: input.phone,
      description: input.descriptionClient,
    };
    fetch("http://localhost:3001/api/form", {
      method: "post",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        console.log(json);
        if (json.type === "SEND_EMAIL_SUCCESS") {
          setInput({
            name: "",
            lastName: "",
            email: "",
            phone: Number(),
            descriptionClient: "",
          });
          setMsg(true);
          setMsgColor("success");
          setMsgText(
            "El mensaje fue enviado con exito! pronto responderemos a su consulta. Muchas gracias!"
          );
        } else {
          setMsg(true);
          setMsgColor("danger");
          setMsgText(
            "El mensaje no fue enviado, por favor vuelvalo a intentar o comuniquese por el medio telefónico. Disculpas y Muchas gracias!"
          );
        }
      });
  };

  const responseEmail = (
    <div>
      <Alert variant={msgColor}>{msgText}</Alert>
    </div>
  );

  return (
    <section className="commonSection ContactPage">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h4 className="sub_title">{subTitle}</h4>
            <h2 className="sec_title">{title}</h2>
            <p className="sec_desc">{description}</p>
            {msg ? responseEmail : ""}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
            <form
              /* action="https://getform.io/f/00b8c052-bd9e-4a57-8db5-a7468c7bd079" */
              /* method="post" */
              className="contactFrom"
              id="contactForm"
              autoComplete="off"
              onSubmit={e => sendPost(e)}
            >
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="text"
                    name="name"
                    id="f_name"
                    placeholder="Nombre"
                    value={input.name ? input.name : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="text"
                    name="lastName"
                    id="l_name"
                    placeholder="Apellido"
                    value={input.lastName ? input.lastName : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form required"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Dirección de email"
                    value={input.email ? input.email : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <input
                    className="input-form"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Número de teléfono"
                    value={input.phone ? input.phone : ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <textarea
                    className="input-form required"
                    name="descriptionClient"
                    id="con_message"
                    placeholder="Explica tu proyecto..."
                    value={
                      input.descriptionClient ? input.descriptionClient : ""
                    }
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <button
                className="common_btn red_bg"
                type="submit"
                id="con_submit"
              >
                <span>Enviar</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
